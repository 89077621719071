$.lui("part", ".part_form_booking", function(selector) {
    selector.each(function(){
        let selector = $(this);

        (function fn_datepicker() {
            let checkin = selector.find('[data-form-datepicker="arrival"]');
            let checkout = selector.find('[data-form-datepicker="departure"]');

            if (checkin.length && checkout.length) {
                let minDate = new Date();
                let maxDate = new Date();
                maxDate.setFullYear(new Date().getFullYear() + 1);

                let lang_url;

                if (lang === "ru") {
                    lang_url = cdnjs.datepicker_lang;
                } else {
                    lang_url = cdnjs.datepicker_lang.replace(".cs.", `.${lang}.`);
                }

                $.importScript(cdnjs.datepicker, function(){
                    $.importScript(lang_url, function () {

                        $(checkin).each(function(){
                            let checkin = $(this);
                            let checkout = checkin.closest(".part_form_booking").find('[data-form-datepicker="departure"]');

                            checkin.find('input:not([type="hidden"])').datepicker({
                                language: lang,
                                position: "top center",
                                minDate: minDate,
                                maxDate: maxDate,
                                startDate: new Date(),
                                autoClose: true,
                                dateFormat: lang === 'cs' ? 'dd.mm.yyyy' : 'dd/mm/yyyy',
                                altField: checkin.find('input[type="hidden"]'),
                                altFieldDateFormat: 'yyyy-mm-dd',
                                onShow: function () {
                                    doc.find(".datepickers-container").addClass("state--active").children(".datepicker").attr("data-title", checkin.data("title"));
                                },
                                onHide: function () {
                                    doc.find(".datepickers-container").removeClass("state--active");
                                },
                                onSelect: function (fd, d) {
                                    let dateOut = new Date(d.getTime());
                                    dateOut.setDate(dateOut.getDate() + 1);
                                    checkout.find('input:not([type="hidden"])').datepicker().data('datepicker').selectDate(dateOut);

                                    checkout.find('input:not([type="hidden"])').datepicker().data('datepicker').update({
                                        minDate: dateOut
                                    });
                                }
                            }).data('datepicker');
                        });

                        $(checkout).each(function(){
                            let checkout = $(this);

                            checkout.find('input:not([type="hidden"])').datepicker({
                                language: lang,
                                position: "top center",
                                startDate: new Date(),
                                autoClose: true,
                                minDate: minDate,
                                dateFormat: lang === 'cs' ? 'dd.mm.yyyy' : 'dd/mm/yyyy',
                                altField: checkout.find('input[type="hidden"]'),
                                altFieldDateFormat: 'yyyy-mm-dd',
                                onShow: function () {
                                    doc.find(".datepickers-container").addClass("state--active").children(".datepicker").attr("data-title", checkout.data("title"));
                                },
                                onHide: function () {
                                    doc.find(".datepickers-container").removeClass("state--active");
                                }
                            }).data('datepicker');
                        });
                    });
                });
            }
        })();

        selector.on("click", `[data-click^="number"]`, function (e) {
            e.preventDefault();
            let elm = $(this);
            let inp = elm.closest(".elm_input_inner").find("input");
            let num;

            if (elm.filter('[data-click="number-plus"]').length) {
                num = +inp.val() + inp.data('step');
                if (num <= inp.attr('max')) {
                    inp.val(num).trigger("change");
                }
            }
            if (elm.filter('[data-click="number-minus"]').length) {
                num = +inp.val() - inp.data('step');
                if (num >= inp.attr('min')) {
                    inp.val(num).trigger("change");
                }
            }
        });
    });
});